export enum LocalStorageEnum {
    token = '_t',
    token_expires_at = '_tea',
    user_id = '_u',
    user_profile = '_uf',
    user_firstname = '_ufn',
    user_lastname = '_uln',
    user_role = '_ur',
    refresh_token = '_rft',
    permissions = '_up',
    lang = 'lang',
    username = 'username',
    language = 'language',
    organization = '_org',
}
